import axios, { AxiosResponse } from "axios";

export const version = {
  releaseDate: "08/01/2025",
  commit: "949aa35314a1bc5f6cc933eff2708ad224562af5",
  environment: process.env.VUE_APP_NODE_ENV,
  number: "support/2403.0.2",
};

export const getAPIVersion = async () => {
  try {
    const appURL = process.env.VUE_APP_API_URL;
    const splitURL = appURL ? appURL.split("/api/v1").join("") : "";
    const response = await fetch(`${splitURL}/version`);

    if (response) {
      return await response.json();
    }
  } catch (err) {
    console.error(err);
  }
};
